@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :focus,
  :focus-visible,
  :focus-within {
    outline: none;
  }
}

@layer components {
  [debug-screen]::after {
    position: fixed;
    bottom: 5px;
    right: 5px;
    background-color: black;
    color: white;
    padding: 10px;
    font-family: monospace;
    font-size: 11px;
    line-height: 10px;
    z-index: 2147483638;
  }
  
  [debug-screen]::after { content: 'unset'; }
  :root { --breakpoint: 'unset'; }
  
  @screen sm {
    :root { --breakpoint: 'sm'; }
    [debug-screen]::after { content: 'sm'; }
  }
  
  @screen md {
    :root { --breakpoint: 'md'; }
    [debug-screen]::after { content: 'md'; }
  }
  
  @screen lg {
    :root { --breakpoint: 'lg'; }
    [debug-screen]::after { content: 'lg'; }
  }
  
  @screen xl {
    :root { --breakpoint: 'xl'; }
    [debug-screen]::after { content: 'xl'; }
  }
  @screen 2xl {
    :root { --breakpoint: '2xl'; }
    [debug-screen]::after { content: '2xl'; }
  }
  @screen 3xl {
    :root { --breakpoint: '3xl'; }
    [debug-screen]::after { content: '3xl'; }
  }
}

@layer components {
  .text-nav {
    font-size: 18px;
    line-height: 25px;
  }
  .text-cta-block-button {
    font-size: 15px;
  }
  .text-editor {
    font-size: 15px;
    line-height: 22px;
  }
  .text-lead {
    font-size: 18px;
    line-height: 25px;
  }
  .text-h1 {
    font-family: PP Pangram Sans;
    font-size: 40px;
    font-weight: 800;
    line-height: 55px;
    letter-spacing: -1px;
    -moz-font-feature-settings: 'ss01';
    -webkit-font-feature-settings: 'ss01';
    font-feature-settings: 'ss01' on;
  }
  
  .text-3xl {
    font-size: 45px;
    line-height: 1;
    letter-spacing: -1px;
    -moz-font-feature-settings: 'ss01';
    -webkit-font-feature-settings: 'ss01';
    font-feature-settings: 'ss01' on;
  }

  .text-2xl {
    font-size: 32px;
    line-height: 32px;
    letter-spacing: -1px;
  }
  .text-xl {
    font-size: 35px;
    line-height: 40px;
  }
  .text-l {
    font-size: 18px;
    line-height: 25px;
    -moz-font-feature-settings: 'ss01';
    -webkit-font-feature-settings: 'ss01';
    font-feature-settings: 'ss01' on;
  }
  .text-m {
    font-size: 15px;
    line-height: 22px;
  }
  .text-s {
    font-size: 12px;    
    line-height: 15px;
    letter-spacing: 1.5px;
  }
  .text-button {
    font-size: 15px;
    line-height: 1;
  }

  .text-extra {
    font-size: 80px;
    line-height: 1;
  }
  .text-menu-section {
    font-size: 45px;
    line-height: 1;
    letter-spacing: -1px;
  }
  .text-cookie {
    font-size: 10px;
    line-height: 12px;
  }
  .text-menu {
    font-size: 14px;
  }
  .text-cards-name {
    font-size: 20px;
    line-height: 25px;
  }
  @screen sm {
    .text-extra {
      font-size: 65px;
      line-height: 1;
    }
    .text-cta-block-button {
      font-size: 18px;
    }
    .text-menu-section {
      font-size: 80px;
      line-height: 1;
      letter-spacing: -1px;
    } 
    .text-menu {
      font-size: 16px;
    }
    .text-cards-name {
      font-size: 30px;
      line-height: 35px;
    }
  }
  @screen md {
    .text-editor {
      font-size: 20px;
      line-height: 30px;
    }
    .text-lead {
      font-size: 20px;
      line-height: 30px;
    }
    .text-3xl {
      font-size: 6.944444vw;
      line-height: 1.1;
      letter-spacing: -0.01em;
    }
    .text-2xl {
      font-size: 4.861111vw;
      line-height: 1.071429;
      letter-spacing: -0.01em;
    }
    .text-xl {
      font-size: 3.819444vw;
      line-height: 1.090909;
    }
    .text-l {
      font-size: 25px;
      line-height: 30px;
    }
    .text-m {
      font-size: 16px;
      line-height: 25px;
    }
    .text-s {
      font-size: 15px;    
      line-height: 20px;
      letter-spacing: 0.02em;
    }
    .text-button {
      font-size: 1.25vw;
      line-height: 1.111111;
    }

    .text-extra { font-size: 80px; }
    .text-menu-section { font-size: 100px; }
    .text-cookie {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @screen lg {
    .text-extra { font-size: 125px; }
  }

  @screen 2xl {
    .text-3xl {
      font-size: 6.25vw;
      line-height: 1.041667;
      letter-spacing: -0.01em;
    }
    .text-2xl {
      font-size: 4.3vw;
      line-height: 1.05;
      letter-spacing: -0.01em;
    }
    .text-xl {
      font-size: 3.385417vw;
      line-height: 1.076923;
    }
    .text-l {
      font-size: 1.5625vw;
      line-height: 1.166667;
    }
    .text-m {
      font-size: 1.145833vw;
      line-height: 1.363636;
    }
    .text-s {
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0.02em;
    }
    .text-button {
      font-size: 0.9375vw;
      line-height: 1.111111;
    }
    .text-extra { font-size: 140px; }
    .text-menu-section { font-size: 6.25vw; }
  }

  @screen 3xl {
    .text-2xl {
      font-size: 80px;
      line-height: 1.05;
      letter-spacing: -0.01em;
    }
  }

  .text-xs {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.5px;
  }

  .cookie-editor a {
    @apply font-bold;
  }

  .has-href a {
    @apply underline hover:no-underline;
  }

  .editor :first-child {
    margin-top: 0;
  }
  .editor :last-child {
    margin-bottom: 0;
  }
  .editor h2 {
    @apply font-bold mb-[20px] mt-[35px] sm:mb-[30px] sm:mt-[40px];
    font-size: 15px;
    line-height: 22px;
  }
  .editor ul {
    @apply mb-[35px] sm:mb-[70px] list-disc mt-[1em];
  }
  .editor li {
    @apply mb-[1em] ml-[30px];
  }

  .editor a {
    @apply font-bold;
  }

  .editor b {
    @apply font-bold;
  }

  @screen sm {
    .editor h2 {
      font-size: 30px;
      line-height: 35px;
    } 
  }

  .text-newsletter { 
      font-size: 35px;
      line-height: 1;
      letter-spacing: -1px;
      -moz-font-feature-settings: 'ss01';
      -webkit-font-feature-settings: 'ss01';
      font-feature-settings: 'ss01' on;
  }

  @screen lg {
    .text-newsletter {
      font-size: 70px;
    }
  }

  .text-about-card-heading {
    @apply font-bold text-center;
    font-size: 30px;
    line-height: 36px;
  }
}

@layer utilities {
  .nav-shadow {
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.20);
  }
}
